@font-face {
    font-family: "Bender";
    font-style: normal;
    font-weight: 300;
    src: url("../media/fonts/bender/300/regular/webfont.woff2") format("woff2"), url("../media/fonts/bender/300/regular/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: italic;
    font-weight: 300;
    src: url("../media/fonts/bender/300/italic/webfont.woff2") format("woff2"), url("../media/fonts/bender/300/italic/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: normal;
    font-weight: normal;
    src: url("../media/fonts/bender/400/regular/webfont.woff2") format("woff2"), url("../media/fonts/bender/400/regular/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: italic;
    font-weight: 400;
    src: url("../media/fonts/bender/400/italic/webfont.woff2") format("woff2"), url("../media/fonts/bender/400/italic/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: normal;
    font-weight: 700;
    src: url("../media/fonts/bender/700/regular/webfont.woff2") format("woff2"), url("../media/fonts/bender/700/regular/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: italic;
    font-weight: 700;
    src: url("../media/fonts/bender/700/italic/webfont.woff2") format("woff2"), url("../media/fonts/bender/700/italic/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: normal;
    font-weight: 900;
    src: url("../media/fonts/bender/900/regular/webfont.woff2") format("woff2"), url("../media/fonts/bender/900/regular/webfont.woff") format("woff");
}

@font-face {
    font-family: "Bender";
    font-style: italic;
    font-weight: 900;
    src: url("../media/fonts/bender/900/italic/webfont.woff2") format("woff2"), url("../media/fonts/bender/900/italic/webfont.woff") format("woff");
}
