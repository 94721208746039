*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
   box-sizing: inherit;
}
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 0.5em; }
img { height: auto; max-width: 100%; }
img.border { border: 1px solid #000; }
a { color: #000; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none;}
a:hover,a:active { outline: 0; }
a:hover { color: #19558f; }
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
   content: "";
   display: table;
   table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
   clear: both;
}

/*-------------------------- THEME CSS ----------------------*/
html { position: relative; min-height: 100%; }
body { font-size: 19px; color: #252525; letter-spacing: 0; line-height: 30px;  overflow-x: hidden; font-family: 'Source Sans Pro', sans-serif; }
ul.no-list,ol.no-list,ul.bxslider { margin: 0; padding: 0; list-style: none; }
ul.no-list li { display: inline-block; }
a.button { font-size: 18px; padding: 7px 35px 6px; border-radius: 50px; letter-spacing: 0.22px; text-transform: uppercase; font-family: "Bender"; font-style: normal;   font-weight: 400; line-height: 22px; }
a.btn-banner { font-size: 18px; color: #FFFFFF; border: 2px solid #FFF; padding: 6px 25px; border-radius: 50px; margin-top: 30px; display: inline-block; text-transform: uppercase; }
a.btn-banner:hover { color: #BB2025; border: 2px solid #BB2025 }
.container { width: 1200px; padding-left: 15px; padding-right: 15px; }
.container-large { width: 1260px; padding-left: 15px; padding-right: 15px; margin: 0 auto; }
h1,h2,h3,h4,h5,h6 { font-family: "Bender"; font-style: normal; font-weight: 400; letter-spacing: 0.44px; color: #BB2025; text-transform: uppercase; }
h1 { font-size: 36px; line-height: 44px; margin-bottom: 25px; }
.content-section a.button { background-color: #000000; color: #ffffff; white-space: nowrap; }
.content-section p { margin-bottom: 15px; }
.content-section li { margin-left: 5px; }

.row { display: flex; flex-direction: row; margin: 0; }
.col.-half { flex: 0 0 50%; }
.col.-third:not(:last-child) { flex: 0 0 33%; padding-right: 15px }

/*-------------------- HEADER CSS --------------------------*/
.site-header { padding: 40px 0; position: relative; }
.site-header:before { background: rgba(0,0,0,0.9) url(../media/bitmap-left.png) no-repeat 0px 0px; content: ""; position: absolute; top: 0; left: -2%; width: 100%; height: 165px; z-index: 0; -ms-transform: skewX(8deg); -webkit-transform: skewX(8deg); transform: skewX(8deg); }
.site-header .header-inner:after { content: ""; position: absolute; top: 0px; height: 165px; width: 64%; background:url(../media/bitmap-right.png) no-repeat 0px 0px;  left: auto; right: 0; z-index: 0; background-size: cover; }
.logo a { display: inline-block; position: relative; z-index: 1; }
.logo img { width: 100%; }
.header-title { font-size: 28px; color: #000; }
.navigation ul { margin: 0; padding: 0; list-style: none; }
.navigation ul li { display: inline-block; }
.navigation ul li a { font-size: 16px; color: #FFFFFF; letter-spacing: 1px; padding: 10px 13px; text-transform: uppercase; font-family: "Bender"; font-weight: 400; }
.navigation ul li a:hover,.navigation ul li.current-page-ancestor > a,.navigation ul li.current-menu-item > a { color: #252525; }
.navigation ul li:first-child a { padding-left: 0; }
.navigation ul li:last-child a { padding-right: 0; }

.sub-menu { display: none; }
.navigation ul li:hover > ul.sub-menu { display: block; position: absolute; top: 0; width: 100%; max-width: 200px; margin-top: 35px; background: rgba(205,35,40,1); padding: 5px 15px 5px 15px; -webkit-box-shadow: 0px 2px 10px #909090; -ms-box-shadow: 0px 2px 10px #909090; box-shadow: 0px 2px 10px #909090 }

.header-right { position: relative; padding-left: 55px; margin: 10px 0 0;}
.header-title { margin-bottom: 10px; letter-spacing: 0.3px; font-family: "Bender"; font-style: italic; font-weight: 400; }
.header-title,.navigation { position: relative; z-index: 2; }

/*-------------------- FOOTER CSS -------------------------*/
.site-footer { background: rgba(0,0,0,0.9) url('../media/footer-bg.png') no-repeat top left; padding: 45px 0 40px; background-size: cover; }
.site-footer .footer-logo { padding-right: 0; }
.footer-logo img { width: 100%; }
.site-footer .footer-info { padding-left: 45px; }
.site-footer .footer-info p { margin: 0; }
.site-footer .footer-info p a,.site-footer { font-size: 14px; color: #9B9B9B; letter-spacing: 0; line-height: 24px; }
.client-logo ul li { padding-bottom: 20px; padding-left: 30px; }

/*---------------- SLIDER CSS -----------------*/
.banner-section { padding: 50px 0; background-size: cover; background-repeat: no-repeat; }
.banner-section .bx-wrapper { background: url(../media/bx_loader.gif); background-repeat: no-repeat; background-position: center; }
.banner-section .bx-wrapper .bx-viewport,.banner-section ul li { opacity: 0; }
.bx-wrapper .bx-viewport { border: 0px none; left: 0; border: 1px solid #CCC; box-shadow: -1px 2px 7px #909090; border-bottom: 0px; }
ul.bxslider li { background-repeat: no-repeat; background-position: top left; overflow: hidden;  position: relative;}
.banner-section.active .bx-wrapper .bx-viewport,.banner-section.active ul li { opacity: 1; }
.banner-section.active .bx-wrapper { background: transparent; }
.bx-wrapper img { opacity: 0; }
.banner-content { position: absolute; top: 0; right: 0; max-width: 450px; background: #252525; color: #FFF; padding: 60px 60px 60px 35px; bottom: 0; }
.banner-content .banner-title { margin-top: 10px; font-size: 24px; line-height: 36px; margin-bottom: 10px; font-weight: 700; text-transform: uppercase; }
.banner-content .banner-title a { color: #FFF; }
.banner-content p { font-size: 16px; color: #9B9B9B; letter-spacing: 0; line-height: 26px; }
.banner-content:before { background: #252525;content: "";position: absolute;top: 0;left: -40px;width: 65px;height: 100%;z-index: 0;-ms-transform: skewX(-5deg);-webkit-transform: skewX(-5deg);transform: skewX(-5deg);display: inline-block; }

/*---------------------- WELCOME CSS -------------------*/
.welcome-section { padding: 55px 0; }
.welcome-btn { text-align: center; }
.welcome-btn a.button { color: #BB2025; border: 2px solid #BB2025; margin-top: 15px; }
.welcome-btn a.button:hover { color: #252525; border: 2px solid #252525; }
.service-blocks { margin-top: 55px; }
.block-single { display: flex; flex: 1; width: 100%; }
.block-single .cover { background-size: cover; background-position: center; background-repeat: no-repeat; position: relative; -webkit-box-shadow: -1px 2px 7px #909090; -moz-box-shadow: -1px 2px 7px #909090; box-shadow: -1px 2px 7px #909090; background-color: rgba(37,37,37,1); }

.block-single img { height: 0; opacity: 0; width: 100%; }
.block-data { text-align: center; padding: 30px; }
.block-data h3 { color: #FFF; margin-bottom: 15px; letter-spacing: 2.67px; font-family: "Bender"; font-style: normal; font-weight: 400; text-transform: uppercase; margin-top: 0; }
.block-data p { font-size: 14px; color: #DCDCDC; line-height: 24px; text-align: left; margin-bottom: 10px; }
.block-single:last-child .services { background-size: auto 100%; position: relative; z-index: 1111; background-position: top right; background-color: rgba(33,33,33,1);}
.block-single:last-child .block-data a { font-size: 18px; color: #ED2224; letter-spacing: 0.22px; line-height: 24px; display: inline-block; font-family: "Bender";    font-style: normal; font-weight: 400; }
.block-single:last-child .block-data a span { display: block; }
.block-single .cover:hover,.block-single .cover.open { background-color: rgba(191,32,37,1); }
.block-single .cover:hover > .block-data a { color: #000; }

/*----------------------- SERVECE PAGE CSS ------------------*/
.content-section { background-repeat: repeat; background-position: top left; padding: 40px 0; background-position: center top;}
.service-list { padding-top: 60px; }
.content-section .service-list:nth-child(even) .col-sm-4 { float: right; }
.content-section .service-list:last-child { padding-bottom: 60px; }
.service-list h3,.eitor-data h3 { margin-top: 0; font-style: italic; font-size: 26px; color: #4a4a4a; letter-spacing: 0.32px; text-transform: inherit; }
.service-list h3 { margin-top: -3px; }
.service-list p { margin: 0 0 15px; }

/*--------------------- EDITOR DATA -------------------------*/
.editor-second { padding: 40px 0 100px; }
.eitor-data p { margin: 0 0 15px; }
.eitor-data ul { margin-top: 35px; padding-left: 30px; }
.eitor-data ul li { margin-bottom: 15px; padding-left: 5px; }
.featured-image { position: relative; margin-left: 30px; margin-bottom: 20px; margin-top: 5px; }
.featured-image:after { content: ""; position: absolute; bottom: -95px; right: 25px; background: url(../media/polys.png) no-repeat; width: 145px; height: 127px; }
