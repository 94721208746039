@media (max-width:1400px) {
    .container { width: 1120px; }
    .container-large { width: 1160px; }
    .site-header .header-inner:after { width: 67%; }

    .header-right { padding-left: 20px; margin: 10px 0 0; }
    .header-title { margin-bottom: 5px; }
    .navigation ul li a { padding: 10px 8px; }
}

@media (max-width:1199px) {
    body { font-size: 16px; line-height: 26px; }
    h1 { font-size: 30px; line-height: 38px; margin-bottom: 15px; }
    h3,.h3,.service-list h3,.eitor-data h3 { font-size: 20px; line-height: 26px; margin-top: 10px; }
    .container { width: 960px; }
    .container-large { width: 992px; }
    .site-header { padding: 30px 0; }
    .logo img { max-width: 218px; width:100%; }
    .header-right { padding-left: 50px; margin: 0; }
    .header-title { font-size: 24px; }
    .navigation ul li a { font-size: 14px; padding: 10px; }
    .site-header:before { height: 122px; background-size: cover; }
    .site-header .header-inner:after { width: 73%; height: 122px; background-size: cover; }
    .header-right:after { left: -20px; height: 122px; top: -30px; }
    .banner-section { padding: 30px 0; }
    .bx-wrapper img { height: 350px; }
    .banner-content { max-width: 300px; padding: 40px 15px 15px 35px; }
    .banner-content:before { left: -15px; width: 30px; }
    .banner-content .banner-title { font-size: 16px; line-height: 24px; }
    .banner-content p { font-size: 14px; line-height: 24px; }
    a.button { font-size: 16px; line-height: 24px; }
    a.btn-banner { margin-top: 20px; }
    .welcome-section { padding: 35px 0; }
    .block-data { padding: 20px; }
    .block-data p { font-size: 16px; line-height: 24px; }
    .block-single:last-child .block-data a { font-size: 14px; line-height: 22px; }
    .site-footer .footer-info p a, .site-footer { font-size: 14px; }

    .content-section { padding: 20px 0; }
    .service-list h3  { margin-top: -5px; margin-bottom: 5px; }
    .service-list { padding-top: 30px; }

    .editor-second { padding: 20px 0 70px; }
    .featured-image:after { right: 20px; background-size: cover; width: 130px; height: 115px; bottom: -85px; }

    .navigation ul li.open > ul.sub-menu { display: block; position: absolute; top: 0; left: 0px; width: 100%; max-width: 165px; margin-top: 35px; background: rgba(205,35,40,1); padding: 5px 15px 5px 15px; -webkit-box-shadow: 0px 2px 10px #909090; -ms-box-shadow: 0px 2px 10px #909090; box-shadow: 0px 2px 10px #909090 }

    .featured-image { margin-top: 20px; max-width: 300px; }
}
@media (max-width:991px) {
    body { font-size: 14px; line-height: 22px; }
    h1 { font-size: 26px; line-height: 32px; margin-bottom: 10px; }
    h3, .h3 { font-size: 20px; line-height: 24px; }
    .container-large,.container { width: 760px; }
    .logo { padding-left: 30px; }
    .logo img { max-width: 205px; width:100%; }
    .site-header:before { height: 117px; }
    .site-header .header-inner { overflow-x:  hidden; }
    .site-header .header-inner:after { width: 21%; height: 117px; }
    .header-right { padding: 30px 0; position: fixed; right: -240px; top: 0; height: 100%; width: 240px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background: rgba(208,35,40,1); text-align: left; overflow: auto; }
    .mobile-menu { position: absolute; top: 50%; right: 50px; z-index: 20; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); transform: translateY(-50%); background: url(../media/btn-mobile.png); }
    .mobile-menu img { opacity: 0; }
    .open-menu .mobile-menu { background: url(../media/btn-close.png); }
    .mobile-menu a { display: inline-block; position: relative;  z-index: 1; }
    .open-menu .header-right { right: 0; }
    .navigation ul li { display: block; overflow: hidden; }
    .navigation ul li:last-child { border-bottom: 0px; }
    .navigation ul li:first-child a,.navigation ul li a,.navigation ul li:last-child a { padding: 7px 10px; display: inline-block; position: relative; line-height:18px; }
    .navigation ul li a:before { content: ""; position: absolute; bottom: 0; left: 0; right: 0; background: #FFF; height: 1px; width: 500%; }
    .site-footer, .site-content, .site-header {  -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
    .open-menu .site-footer, .open-menu .site-content, .open-menu .site-header { left: -240px; position: relative; }

    .navigation ul li:hover > ul.sub-menu,.navigation ul li.open > ul.sub-menu { position: relative; top: 0; left: 0px; max-width: 100%; padding: 0; -webkit-box-shadow: none; -ms-box-shadow: none; box-shadow: none; margin: 0; }
    .navigation ul.sub-menu li a { padding-left: 20px; }
	.navigation ul li.menu-item-has-children > a {width: 100%;}
	.navigation ul li.menu-item-has-children > a:after {content: "+"; float: right; font-size: 30px;}

    .header-title { font-size: 14px; line-height: 18px; padding: 0 15px; }
    .site-footer { padding: 30px 0 40px; }
    .site-footer .footer-info p a, .site-footer { text-align: center; }
    .site-footer .client-logo { float: none !important; text-align: center; }
    .banner-section { padding: 0; }
    .banner-section .container { padding: 0; width: 100%; }
    .bx-wrapper .bx-viewport { border: 0px none; left: 0; border: 0; box-shadow: none; border-bottom: 0px; }
    ul.bxslider li { background: transparent !important; background-size: cover; }
    .bx-wrapper img { height: auto; width: 100%; opacity: 1; }
    .service-blocks { margin-top: 40px; }
    .banner-content { max-width: 100%; padding: 20px 40px 40px; position: relative; }
    .banner-content:before { content: none; }
    .block-data { padding: 20px; }
    .block-data p { font-size: 14px;line-height: 22px; }
    .block-single:last-child .block-data a {font-size: 12px; line-height: 18px; }

    .content-section { padding: 10px 0; }
    .service-list { padding-top: 15px; }
    .service-list h3  { margin-top: -5px; margin-bottom: 5px; font-size: 18px; }
    .service-list p { margin: 0 0 10px; }

    .eitor-data h3 { font-size: 18px; }
    .featured-image:after { right: 30px; background-size: cover; width: 80px; height: 70px; bottom: -55px; }

    .header-right:after { content: none; }

    .featured-image { max-width: 250px; }

}
	
@media (max-width:767px) {
    .container-large,.container { width: 100%; padding: 0 15px; }
    h3, .h3 { font-size: 18px; line-height: 22px; }
    h1 { font-size: 22px; line-height: 30px; margin: 15px 0;}
    .site-header { padding: 20px 0; }
    .logo { padding-left: 20px; }
    .logo img { width: 175px; }
    .site-header:before { height: 88px; }
    .site-header .header-inner:after { width: 105px; height: 88px; right: 0%; }
    .header-right { padding: 30px 0; }
    .mobile-menu { right: 25px; top: 43px; }
    .open-menu .mobile-menu { top: 43px; }
    .banner-content { padding: 13px 40px 23px; text-align: center; }
    .banner-content .banner-title { font-size: 18px;line-height: 24px; margin-bottom: 5px; text-align: left; }
    .banner-content p { font-size: 12px; line-height: 18px; text-align: left; color: #FFF; }
    a.button { font-size: 15px; line-height: 19px; padding: 6px 20px; }
    a.btn-banner { margin-top: 5px; display: inline-block; }
    .welcome-btn a.button { letter-spacing: 0; font-weight: 700; padding: 6px 20px; }
	.service-blocks { display:block; }
    .block-single { display: block; padding: 0; }
    .block-data { padding: 20px 40px 20px 30px; }
    .block-data h3 { margin-bottom: 10px; margin-top: 10px; }
    .block-data p{ font-size: 12px; line-height: 22px; }
    .block-single:last-child .block-data a { display: block; }
    .site-footer .col-md-2 img { width: 170px; }
    .client-logo ul li { padding-left: 15px; }
    .client-logo ul li:first-child { padding-left: 0; }
    .client-logo ul li img { width: 78px; }

    .content-section { background: transparent !important; }
    .service-list { padding-top: 45px; }
    .service-list h3,.eitor-data h3 { font-size: 17px;  margin: 10px 0; line-height: 20px; }
    .service-list h3 { margin: 13px 0 10px; }
    .content-section .service-list:nth-child(even) .col-sm-4  { float: none; }
    .service-image img { width: 100%; }

    .featured-image { float: none !important; text-align: center; margin-bottom: 15px; margin-top: 0; max-width: 100%; margin-left: 0; }
    .featured-image img { max-width: 230px; }
    .featured-image:after { content: none; }
    .editor-second { padding: 50px 0; }
    .eitor-data h3 { text-align: center; }
    .eitor-data ul { margin-top: 25px; }

    .block-single .cover { -webkit-box-shadow: -1px 2px 7px #000; -moz-box-shadow: -1px 2px 7px #000; box-shadow: -1px 2px 7px #000; }
    .block-data p { text-align: center; }

    .block-single:last-child .cover:hover,.block-single:last-child .cover.open { background-color: rgba(33,33,33,1); }
    .block-single:last-child .cover:hover > .block-data a { color: #ED2224; }

    .site-footer .row { flex-flow: column; }
    .client-logo { margin-top: 20px; }
	
	.alignleft, .alignright { display: block; float: none; margin: 0 auto; }

}
@media (max-width:570px) {
	.row { display: block; }
	.col:not(:first-child) > ul { margin-top: -20px; }
}
@media (max-width:480px) {
	.alignleft, .alignright {
		display: block;
		float: none;
		margin: 0 auto 15px;
	}
}
@media (max-width:375px) {
    .block-data p { text-align: left; }
    .welcome-btn a.button { padding: 6px 15px; }
}
